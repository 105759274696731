import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { CarouselType, Metadata } from '../../../services/types';

import NFTCarouselCardCurrent from './NFTCarouselCardCurrent';
import NFTCarouselCardLoader from './NFTCarouselCardLoader';
import NFTCarouselCardSingle from './NFTCarouselCardSingle';

interface NFTCarouselCardsProps {
  carouselData: CarouselType;
}

const NFTCarouselCards = ({ carouselData }: NFTCarouselCardsProps) => {
  const { t } = useTranslation();

  const displayCarousel = () => {
    if (carouselData.current == undefined) {
      return [...Array(7)].map((_: any) => {
        return <NFTCarouselCardLoader />;
      });
    } else {
      if (carouselData.current.index == 0) {
        return (
          <>
            <NFTCarouselCardCurrent
              nftPrice={carouselData.nftPrice}
              imageModal={carouselData.current.data.image}
              tokenId={carouselData.current.data.tokenId}
              totalSupply={carouselData.totalSupply}
            />
            {carouselData.next.map((data: Metadata, index: number) => {
              return (
                <NFTCarouselCardSingle
                  key={index}
                  imageModal={data.image}
                  nftPrice={carouselData.nftPrice}
                  tokenId={data.tokenId}
                  totalSupply={carouselData.totalSupply}
                  barText={t('NEXT')}
                  cardWidth={200}
                  location="next"
                />
              );
            })}
          </>
        );
      } else if (carouselData.current.index != 0 && carouselData.current.index < 3) {
        return (
          <>
            {carouselData.previous.map((data: Metadata, index: number) => {
              return (
                <NFTCarouselCardSingle
                  key={index}
                  imageModal={data.image}
                  nftPrice={carouselData.nftPrice}
                  tokenId={data.tokenId}
                  totalSupply={carouselData.totalSupply}
                  barText={t('PREVIOUS')}
                  cardWidth={192}
                  location="prev"
                  isSold
                />
              );
            })}
            <NFTCarouselCardCurrent
              nftPrice={carouselData.nftPrice}
              imageModal={carouselData.current.data.image}
              tokenId={carouselData.current.data.tokenId}
              totalSupply={carouselData.totalSupply}
            />
            {carouselData.next.map((data: Metadata, index: number) => {
              return (
                <NFTCarouselCardSingle
                  key={index}
                  imageModal={data.image}
                  nftPrice={carouselData.nftPrice}
                  tokenId={data.tokenId}
                  totalSupply={carouselData.totalSupply}
                  barText={t('NEXT')}
                  cardWidth={192}
                  location="next"
                />
              );
            })}
          </>
        );
      } else if (carouselData.current.index > 3) {
        return (
          <>
            <NFTCarouselCardSingle
              imageModal={carouselData.previous[0].image}
              nftPrice={carouselData.nftPrice}
              tokenId={carouselData.previous[0].tokenId}
              totalSupply={carouselData.totalSupply}
              barText={t('PREVIOUS')}
              cardWidth={192}
              location="prev"
              isSold
            />
            <NFTCarouselCardSingle
              imageModal={carouselData.previous[1].image}
              nftPrice={carouselData.nftPrice}
              tokenId={carouselData.previous[1].tokenId}
              totalSupply={carouselData.totalSupply}
              barText={t('PREVIOUS')}
              cardWidth={232}
              location="prev"
              isSold
            />
            <NFTCarouselCardSingle
              imageModal={carouselData.previous[2].image}
              nftPrice={carouselData.nftPrice}
              tokenId={carouselData.previous[2].tokenId}
              totalSupply={carouselData.totalSupply}
              barText={t('PREVIOUS')}
              cardWidth={272}
              location="prev"
              isSold
            />
            <NFTCarouselCardCurrent
              nftPrice={carouselData.nftPrice}
              imageModal={carouselData.current.data.image}
              tokenId={carouselData.current.data.tokenId}
              totalSupply={carouselData.totalSupply}
            />
            <NFTCarouselCardSingle
              imageModal={carouselData.next[0].image}
              nftPrice={carouselData.nftPrice}
              tokenId={carouselData.next[0].tokenId}
              totalSupply={carouselData.totalSupply}
              barText={t('NEXT')}
              cardWidth={272}
              location="next"
            />
            <NFTCarouselCardSingle
              imageModal={carouselData.next[1].image}
              nftPrice={carouselData.nftPrice}
              tokenId={carouselData.next[1].tokenId}
              totalSupply={carouselData.totalSupply}
              barText={t('NEXT')}
              cardWidth={232}
              location="next"
            />
            <NFTCarouselCardSingle
              imageModal={carouselData.next[2].image}
              nftPrice={carouselData.nftPrice}
              tokenId={carouselData.next[2].tokenId}
              totalSupply={carouselData.totalSupply}
              barText={t('NEXT')}
              cardWidth={192}
              location="next"
            />
          </>
        );
      }
    }
  };

  return (
    <div className="flex justify-between items-center space-x-12 left-1/2 pt-80 pb-200 md:pt-60 lg:pb-[18vw]">
      {displayCarousel()}
    </div>
  );
};

export default NFTCarouselCards;
