import classNames from 'classnames';
import React from 'react';

interface PictureProps {
  src: string;
  mdSrc?: string;
  lgSrc?: string;
  xlSrc?: string;
  /**
   * Classes applied to the parent `<picture>` element
   */
  classes?: string;
  /**
   * Classes applied directly to the fallback `<img>` element
   */
  imgElClasses?: string;
}

// TODO: Ideally resolve the tailwind config and import breakpoint values

const Picture = ({ src, mdSrc, lgSrc, xlSrc, classes, imgElClasses }: PictureProps) => {
  return (
    <picture className={classNames('w-full', classes)}>
      {xlSrc && <source media="(min-width: 1200px)" srcSet={xlSrc} />}
      {lgSrc && <source media="(min-width: 992px)" srcSet={lgSrc} />}
      {mdSrc && <source media="(min-width: 768px)" srcSet={mdSrc} />}
      <img alt="" src={src} className={classNames('w-full', imgElClasses)} />
    </picture>
  );
};

export default Picture;
