import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';
import { RenderBuyButtonInterface } from '../../../services/types';

import Picture from './Picture';

interface BuyYourVolcaneerProps {
  renderBuyButton: RenderBuyButtonInterface;
}

const BuyYourVolcaneer = ({ renderBuyButton }: BuyYourVolcaneerProps) => {
  const { t } = useTranslation();

  return (
    <div className="volcaneers-bg-gradient pb-[23vw] pt-64 lg:pt-[4vw] lg:pb-[10vw]">
      <div className="container-slim lg:flex lg:flex-row-reverse lg:gap-40">
        <div className="lg:mt-56">
          <h2 className="font-black text-36 md:text-48 2xl:text-56 mb-12 md:mb-16">
            <Trans>The Start Of NFTs 2.0</Trans>
          </h2>
          <p className="text-20 leading-8">
            <Trans>
              Volcaneers are pivotal in the fact that they are the very first Stateful NFTs to ever be launched. They
              mark both the start of the Value Era of NFTs, and are a significant enough step forward to be dubbed NFTs
              2.0. Stateful NFTs can be a game character, a collectible, a digital asset, or a digital identity and
              accrue value through usage/gameplay. Volcaneers will be pioneering this vision as the first taste players
              can get of Stateful NFTs.
            </Trans>
          </p>
          <div className="mt-48 md:mt-40 w-full sm:max-w-[330px]">
            {renderBuyButton(t('Buy Your Volcaneer'), {
              eventName: AnalyticsEvents.BUY_NFT,
              location: AnalyticsEventLocations.VOLCANEERS_MORE_THAN_AVATARS
            })}
          </div>
        </div>
        <Picture
          classes="mt-64 lg:max-w-[500px] lg:flex-auto lg:shrink-0 lg:mt-0"
          src="/images/volcaneers-gallery-tablet.png"
        />
      </div>
    </div>
  );
};

export default BuyYourVolcaneer;
