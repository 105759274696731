import React from 'react';

import { CarouselType, RenderBuyButtonInterface } from '../../../services/types';

import NFTCarouselCardCurrent from './NFTCarouselCardCurrent';
import NFTCarouselCardSingle from './NFTCarouselCardSingle';

interface NFTComingSoonCarouselCardsProps {
  carouselData: CarouselType;
  renderBuyButton: RenderBuyButtonInterface;
}

const NFTComingSoonCarouselCards = ({ carouselData, renderBuyButton }: NFTComingSoonCarouselCardsProps) => {
  return (
    <div className="flex justify-between items-center space-x-12 left-1/2 pt-80 pb-200 md:pt-60 lg:pb-[18vw]">
      <NFTCarouselCardSingle
        imageModal={carouselData.previous[0].image}
        nftPrice={carouselData.nftPrice}
        tokenId={carouselData.previous[0].tokenId}
        totalSupply={carouselData.totalSupply}
        cardWidth={192}
        isComingSoon
        showTotalSupply={false}
      />
      <NFTCarouselCardSingle
        imageModal={carouselData.previous[1].image}
        nftPrice={carouselData.nftPrice}
        tokenId={carouselData.previous[1].tokenId}
        totalSupply={carouselData.totalSupply}
        cardWidth={232}
        isComingSoon
        showTotalSupply={false}
      />
      <NFTCarouselCardSingle
        imageModal={carouselData.previous[2].image}
        nftPrice={carouselData.nftPrice}
        tokenId={carouselData.previous[2].tokenId}
        totalSupply={carouselData.totalSupply}
        cardWidth={272}
        isComingSoon
        showTotalSupply={false}
      />
      <NFTCarouselCardCurrent
        nftPrice={carouselData.nftPrice}
        imageModal={carouselData.current.data.image}
        tokenId={carouselData.current.data.tokenId}
        totalSupply={carouselData.totalSupply}
        isComingSoon
        showTotalSupply={false}
        renderBuyButton={renderBuyButton}
      />
      <NFTCarouselCardSingle
        imageModal={carouselData.next[0].image}
        nftPrice={carouselData.nftPrice}
        tokenId={carouselData.next[0].tokenId}
        totalSupply={carouselData.totalSupply}
        cardWidth={272}
        isComingSoon
        showTotalSupply={false}
      />
      <NFTCarouselCardSingle
        imageModal={carouselData.next[1].image}
        nftPrice={carouselData.nftPrice}
        tokenId={carouselData.next[1].tokenId}
        totalSupply={carouselData.totalSupply}
        cardWidth={232}
        isComingSoon
        showTotalSupply={false}
      />
      <NFTCarouselCardSingle
        imageModal={carouselData.next[2].image}
        nftPrice={carouselData.nftPrice}
        tokenId={carouselData.next[2].tokenId}
        totalSupply={carouselData.totalSupply}
        cardWidth={192}
        isComingSoon
        showTotalSupply={false}
      />
    </div>
  );
};

export default NFTComingSoonCarouselCards;
