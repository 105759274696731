import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';

import BottomIllustration from '../../../../static/images/illustration-waves-bottom.inline.svg';
import TopIllustration from '../../../../static/images/illustration-waves-top.inline.svg';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import * as Analytics from '../../../services/analytics-utils';
import { getMetamaskDeepLink, isMobileBrowser } from '../../../services/utils';

import { isMetaMaskInstalled } from '../SimpleContent';
import FullwidthIllustration from './FullwidthIllustration';
import Picture from './Picture';
import VolcaneersButton from './VolcaneersButton';

const ConnectWallet = () => {
  const { connectWallet, connected, currentAccount } = useWeb3Context();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(isMobileBrowser());
  }, []);

  const handleBuyButtonClick = () => {
    if (isMobile && typeof window !== 'undefined') {
      window.location.replace(getMetamaskDeepLink(window.location.href));
    } else {
      connectWallet();
      Analytics.event(
        AnalyticsEvents.CONNECT_WALLET,
        currentAccount,
        AnalyticsEventLocations.VOLCANEERS_CONNECT_WALLET_SECTION
      );
    }
  };

  const renderButton = () => {
    if (!isMetaMaskInstalled) {
      return (
        <a
          className="bg-volcaneers-primary-default hover:bg-volcaneers-primary-dark uppercase text-white rounded-[115px] px-24 md:px-44 text-center disabled:bg-volcaneers-separator disabled:cursor-not-allowed disabled:bg-volcaneers-grey py-32 text-20 leading-6 font-bold w-full block"
          target="_blank"
          href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn"
        >
          Install MetaMask Extension
        </a>
      );
    }
    if (!connected) {
      return (
        <VolcaneersButton className="w-full" onClick={() => handleBuyButtonClick()}>
          <Trans>Connect your wallet</Trans>
        </VolcaneersButton>
      );
    }

    return (
      <a
        className="bg-volcaneers-primary-default hover:bg-volcaneers-primary-dark uppercase text-white rounded-[115px] px-24 md:px-44 text-center disabled:bg-volcaneers-separator disabled:cursor-not-allowed disabled:bg-volcaneers-grey py-32 text-20 leading-6 font-bold w-full block"
        target="_blank"
        href="https://paimastudios.com/junglewars/play"
        onClick={() => {
          Analytics.event(
            AnalyticsEvents.PLAY_FOR_FREE,
            currentAccount,
            AnalyticsEventLocations.VOLCANEERS_CONNECT_WALLET_SECTION
          );
        }}
      >
        <Trans>Play now for free</Trans>
      </a>
    );
  };

  return (
    <div className="relative -mt-[20vw] md:-mt-[12vw]">
      <FullwidthIllustration
        illustration={<TopIllustration />}
        classes="fill-volcaneers-secondary-default lg:scale-x-[-1] relative -bottom-[5px]"
        containerClasses="pt-[13vw]"
      />
      <div className="relative bg-volcaneers-secondary-default">
        <div className="container-slim pt-[10vw] pb-[65vw] lg:pb-[5vw] relative z-20">
          <div className="lg:max-w-[540px]">
            <h2 className="text-36 md:text-48 2xl:text-56 font-black text-white mb-16">
              <Trans>Use Your Volcaneer in Other Web3 Games</Trans>
            </h2>
            <p className="text-20 text-white leading-8">
              <Trans>
                Your Volcaneer is compatible with existing NFT standards, meaning that it can be supported in other
                games and can be traded on the secondary market! This means other games can integrate Volcaneer support
                and your explorations through the world of web3 gaming will be with your favorite volcanic adventurer by
                your side.
              </Trans>
            </p>
            <div className="mt-48 sm:max-w-[330px]">{renderButton()}</div>
          </div>
        </div>
      </div>
      <FullwidthIllustration
        illustration={<BottomIllustration />}
        classes="releative -mt-1 fill-volcaneers-secondary-default lg:scale-x-[-1]"
        containerClasses="pt-[13vw]"
      />
      <Picture
        classes="absolute left-0 right-0 -bottom-[10vw] z-10 lg:max-w-[45vw] lg:bottom-[4vw] lg:top-auto lg:left-auto"
        src="/images/volcaneers-connect-wallet-mobile.png"
        mdSrc="/images/volcaneers-connect-wallet-tablet.png"
        lgSrc="/images/volcaneers-connect-wallet.png"
      />
    </div>
  );
};

export default ConnectWallet;
