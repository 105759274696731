import React from 'react';

import { CarouselType, RenderBuyButtonInterface } from '../../../services/types';

import NFTCarouselCardLoader from './NFTCarouselCardLoader';
import NFTCarouselCards from './NFTCarouselCards';
import NFTComingSoonCarouselCards from './NFTComingSoonCarouselCards';

const mockData: CarouselType = {
  previous: [
    {
      status: 'coming soon',
      tokenId: 46,
      name: 'Paima Volcaneer #46',
      image: 'https://arweave.net/ONGb0zaxlq_D4VQfwjLCNezxKzfAVQPcgYKc-wzk7Kw/46.jpg',
      attributes: [
        {
          trait_type: 'Background',
          value: 'Beach'
        },
        {
          trait_type: 'Character',
          value: 'Cybernetic Blue'
        },
        {
          trait_type: 'Head',
          value: 'Mint Sun Hat'
        },
        {
          trait_type: 'Clothes',
          value: 'Superhero'
        },
        {
          trait_type: 'Teeth',
          value: 'Bunny'
        },
        {
          trait_type: 'Accessories',
          value: 'Pet Cat'
        }
      ],
      description: 'This trusty volcanic pioneer will never leave your side on your adventures.'
    },
    {
      status: 'coming soon',
      tokenId: 47,
      name: 'Paima Volcaneer #47',
      image: 'https://arweave.net/ONGb0zaxlq_D4VQfwjLCNezxKzfAVQPcgYKc-wzk7Kw/47.jpg',
      attributes: [
        {
          trait_type: 'Background',
          value: 'Night Mountains'
        },
        {
          trait_type: 'Character',
          value: 'Magma Blue'
        },
        {
          trait_type: 'Head',
          value: 'Earmuffs'
        },
        {
          trait_type: 'Clothes',
          value: 'Pink T-shirt & Socks'
        },
        {
          trait_type: 'Teeth',
          value: 'Bunny'
        },
        {
          trait_type: 'Accessories',
          value: 'Balloon'
        }
      ],
      description: 'This trusty volcanic pioneer will never leave your side on your adventures.'
    },
    {
      status: 'coming soon',
      tokenId: 48,
      name: 'Paima Volcaneer #48',
      image: 'https://arweave.net/ONGb0zaxlq_D4VQfwjLCNezxKzfAVQPcgYKc-wzk7Kw/48.jpg',
      attributes: [
        {
          trait_type: 'Background',
          value: 'Night Mountains'
        },
        {
          trait_type: 'Character',
          value: 'Magma Cyan'
        },
        {
          trait_type: 'Head',
          value: 'Dual Bun #3'
        },
        {
          trait_type: 'Clothes',
          value: 'Yellow T-shirt & Socks'
        },
        {
          trait_type: 'Teeth',
          value: 'Bunny'
        },
        {
          trait_type: 'Accessories',
          value: 'Maracas'
        }
      ],
      description: 'This trusty volcanic pioneer will never leave your side on your adventures.'
    }
  ],
  next: [
    {
      status: 'coming soon',
      tokenId: 50,
      name: 'Paima Volcaneer #50',
      image: 'https://arweave.net/ONGb0zaxlq_D4VQfwjLCNezxKzfAVQPcgYKc-wzk7Kw/50.jpg',
      attributes: [
        {
          trait_type: 'Background',
          value: 'Night Mountains'
        },
        {
          trait_type: 'Character',
          value: 'Magma Orange'
        },
        {
          trait_type: 'Head',
          value: 'Yellow Baseball Cap'
        },
        {
          trait_type: 'Clothes',
          value: 'Leather Jacket'
        },
        {
          trait_type: 'Teeth',
          value: 'Fangs'
        },
        {
          trait_type: 'Accessories',
          value: 'Rocket'
        }
      ],
      description: 'This trusty volcanic pioneer will never leave your side on your adventures.'
    },
    {
      status: 'coming soon',
      tokenId: 51,
      name: 'Paima Volcaneer #51',
      image: 'https://arweave.net/ONGb0zaxlq_D4VQfwjLCNezxKzfAVQPcgYKc-wzk7Kw/51.jpg',
      attributes: [
        {
          trait_type: 'Background',
          value: 'Beach'
        },
        {
          trait_type: 'Character',
          value: 'Magma Cyan'
        },
        {
          trait_type: 'Head',
          value: 'Yellow Cap'
        },
        {
          trait_type: 'Clothes',
          value: 'Hospital Worker'
        },
        {
          trait_type: 'Teeth',
          value: 'Smile'
        },
        {
          trait_type: 'Accessories',
          value: 'Backpack'
        }
      ],
      description: 'This trusty volcanic pioneer will never leave your side on your adventures.'
    },
    {
      status: 'coming soon',
      tokenId: 52,
      name: 'Paima Volcaneer #52',
      image: 'https://arweave.net/ONGb0zaxlq_D4VQfwjLCNezxKzfAVQPcgYKc-wzk7Kw/52.jpg',
      attributes: [
        {
          trait_type: 'Background',
          value: 'Beach'
        },
        {
          trait_type: 'Character',
          value: 'Cybernetic Blue'
        },
        {
          trait_type: 'Head',
          value: 'White Baseball Cap'
        },
        {
          trait_type: 'Clothes',
          value: 'Green Sweatshirt'
        },
        {
          trait_type: 'Teeth',
          value: 'Dracula'
        },
        {
          trait_type: 'Accessories',
          value: 'Balloon'
        }
      ],
      description: 'This trusty volcanic pioneer will never leave your side on your adventures.'
    }
  ],
  current: {
    index: 4,
    data: {
      status: 'coming soon',
      tokenId: 49,
      name: 'Paima Volcaneer #49',
      image: 'https://arweave.net/ONGb0zaxlq_D4VQfwjLCNezxKzfAVQPcgYKc-wzk7Kw/49.jpg',
      attributes: [
        {
          trait_type: 'Background',
          value: 'Night Mountains'
        },
        {
          trait_type: 'Character',
          value: 'Magma Cyan'
        },
        {
          trait_type: 'Head',
          value: 'Faux Hawk #5'
        },
        {
          trait_type: 'Clothes',
          value: 'Orange Puffy Vest'
        },
        {
          trait_type: 'Teeth',
          value: 'Fangs'
        },
        {
          trait_type: 'Accessories',
          value: 'Speakers'
        }
      ],
      description: 'This trusty volcanic pioneer will never leave your side on your adventures.'
    }
  },
  nftPrice: 10,
  totalSupply: 2000
};

interface NFTCarouselProps {
  carouselData: CarouselType;
  renderBuyButton: RenderBuyButtonInterface;
  comingSoonMode?: boolean;
}

const NFTCarousel = ({ carouselData, comingSoonMode, renderBuyButton }: NFTCarouselProps) => {
  return (
    <section className="relative w-full z-10">
      <div className="w-full overflow-hidden flex justify-center items-center container-slim md:max-w-full">
        {comingSoonMode ? (
          <NFTComingSoonCarouselCards carouselData={mockData} renderBuyButton={renderBuyButton} />
        ) : !!carouselData.current ? (
          <NFTCarouselCards carouselData={carouselData} />
        ) : (
          <NFTCarouselCardLoader />
        )}
      </div>
    </section>
  );
};

export default NFTCarousel;
