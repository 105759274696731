import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { CarouselType, RenderBuyButtonInterface } from '../../../services/types';

import NFTCarousel from './NFTCarousel';

interface AffordableNFTsProps {
  carouselData: CarouselType;
  comingSoonMode?: boolean;
  renderBuyButton: RenderBuyButtonInterface;
}

const AffordableNFTs = ({ carouselData, comingSoonMode, renderBuyButton }: AffordableNFTsProps) => {
  return (
    <div className="pt-120 md:pt-200 lg:pt-[12vw] volcaneers-bg-gradient">
      <div className="container-slim">
        <h2 className="font-volcaneers text-48 leading-[56px] md:text-60 2xl:text-96 text-black text-center mb-16">
          <Trans>Affordable NFTs</Trans>
        </h2>
        <p className="text-center text-20 leading-8 2xl:text-24">
          <Trans>
            In order for NFTs to achieve mass adoption they need to be affordable for everyone. Paima Volcaneers have an
            infinite supply enabling them to maintain a low price of entry, yet accrue value as you play.
          </Trans>
        </p>
      </div>
      <NFTCarousel carouselData={carouselData} comingSoonMode={comingSoonMode} renderBuyButton={renderBuyButton} />
    </div>
  );
};

export default AffordableNFTs;
