import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useState } from 'react';

import { useWeb3Context } from '../../../hooks/useWeb3Context';
import * as Analytics from '../../../services/analytics-utils';

import CloseIcon from '../../../../static/images/icon-close.inline.svg';
import OpenIcon from '../../../../static/images/icon-plus.inline.svg';
import BottomIllustration from '../../../../static/images/illustration-waves-bottom.inline.svg';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';
import FullwidthIllustration from './FullwidthIllustration';

const questions: FAQQuestionProps[] = [
  {
    question: <Trans>What are Volcaneers?</Trans>,
    answer: (
      <Trans>
        The Paima Volcaneers are the very first Stateful NFT collection to ever be released. The Volcaneers themselves
        are volcanic adventurers that tag along with you in your travels across the world of web3 gaming. No matter the
        terrain, your personal Volcaneer will always be by your side wearing a stylish set of clothing that will never
        disappoint.
      </Trans>
    )
  },
  {
    question: <Trans>What is a Stateful NFT?</Trans>,
    answer: (
      <Trans>
        In short, Stateful NFTs are a new class of Non-Fungible Tokens (NFTs) which allow your NFT to accrue value as
        you play games. All existing NFTs currently released are akin to a rock, where they may be shaped to look nice,
        but at the end of the day they can never change/improve/grow due to the underlying technology. Stateful NFTs on
        the other hand are more 'alive', meaning that they can develop and grow with new achievements/stats/capabilities
        the more that they are used in games.
      </Trans>
    )
  },
  {
    question: <Trans>Can I use my NFT in any games?</Trans>,
    answer: (
      <Trans>
        Volcaneers fully support the ERC-721 standard, meaning that all existing tooling/games for NFTs will work with
        the Volcaneers. However, this does not mean that all of these will support the Statefulness of the Volcaneers,
        which requires deeper integration on the part of the developers.
      </Trans>
    )
  },
  {
    question: <Trans>What blockchains are the Volcaneers NFTs available on?</Trans>,
    answer: (
      <Trans>
        The Volcaneers are currently deployed on Milkomeda C1, however there have been numerous mumbles and whispers
        throughout the jungle that another volcano may be destined to erupt soon in other blockchain ecosystems as well.
        Stay tuned!
      </Trans>
    )
  },
  {
    question: <Trans>Where do I go to view my NFT after I bought it?</Trans>,
    answer: (
      <>
        <Trans>
          Simply visit the 'My NFTs' page by using the navigation bar at the top of your screen and hovering over the
          'Volcaneers NFT' menu item.
        </Trans>
        <br /><br />
        <Trans>
        Alternatively, you can add it to your Metamask with the following steps:
        </Trans>
        <ol style={{ listStyle: 'decimal', marginLeft: '16px' }}>
          <li><Trans>Go to the "My NFTs" tab above</Trans></li>
          <li><Trans>Open Metamask</Trans></li>
          <li><Trans>Go to the NFT tab in Metamask</Trans></li>
          <li><Trans>Scroll down and click "import NFT"</Trans></li>
          <li><Trans>Enter the address 0xa335d662BB47409e04F06dC7Fd03cEc854530172</Trans></li>
          <li><Trans>Enter the ID of the NFT you see on the My NFTs page</Trans></li>
        </ol>
      </>
    )
  },
  {
    question: <Trans>I'm new to Milkomeda, what is it and how can I use it to buy an NFT?</Trans>,
    answer: (
      <Trans>
        Milkomeda is an exciting project bringing new VM support to L1 blockchain ecosystems such as Cardano and
        Algorand. You can find more information about how to connect and use Milkomeda C1 with the following guide:
        https://dcspark.github.io/milkomeda-documentation/cardano/for-end-users/
      </Trans>
    )
  }
];

interface FAQQuestionProps {
  question: string | ReactElement;
  answer: string | ReactElement;
}

const FAQQuestion = ({ question, answer }: FAQQuestionProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { currentAccount } = useWeb3Context();

  const handleQuestionToggle = () => {
    if (!isOpen) {
      Analytics.event(AnalyticsEvents.FAQ_QUESTION, currentAccount, AnalyticsEventLocations.VOLCANEERS_FAQ, {
        question: question
      });
    }
    setIsOpen(!isOpen);
  };

  return (
    <li
      className={classNames(
        'bg-volcaneers-secondary-light',
        'p-16 pl-24',
        isOpen ? 'rounded-[32px]' : 'rounded-[160px]'
      )}
      role="button"
      onClick={() => handleQuestionToggle()}
      aria-label="Toggle question"
    >
      <div className="flex items-center justify-between w-full">
        <h3 className="text-white font-bold text-20 leading-8 md:flex-1 text-left">{question}</h3>
        <div className="ml-8 fill-white w-[24px] h-[24px] mr-4">{isOpen ? <CloseIcon /> : <OpenIcon />}</div>
      </div>
      {isOpen && (
        <div className="pt-16">
          <hr className="text-[#4C675E]" />
          <p className="pt-16 text-white text-16 leading-7">{answer}</p>
        </div>
      )}
    </li>
  );
};

const FAQ = () => {
  return (
    <div className="relative z-10 -mt-[10vw]">
      <img alt="" src="/images/volcaneers-lava-illustration.svg" className="w-full" />
      <div className="bg-volcaneers-secondary-default pt-80 lg:pt-120 pb-64">
        <div className="container-slim">
          <h2 className="font-black text-white text-36 md:text-48 2xl:text-56 mb-48">
            <Trans>Frequently asked questions</Trans>
          </h2>
          <ul className="space-y-12">
            {questions.map(({ question, answer }, index) => (
              <FAQQuestion question={question} answer={answer} key={index} />
            ))}
          </ul>
        </div>
      </div>
      <FullwidthIllustration
        flipHorizontally
        illustration={<BottomIllustration />}
        classes="relative -mt-1 fill-volcaneers-secondary-default"
        containerClasses="pb-[13vw]"
      />
    </div>
  );
};

export default FAQ;
