import classNames from 'classnames';
import { Link } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';

import { RenderBuyButtonInterface } from '../../../services/types';

import Picture from './Picture';

interface HeroProps {
  withLogo?: boolean;
  renderBuyButton: RenderBuyButtonInterface;
}

const Hero = ({ withLogo, renderBuyButton }: HeroProps) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('volcaneers-bg-gradient', 'pb-[50vw]', 'md:pb-[10vw]', 'lg:[pb-10vw]', 'relative')}>
      <div
        className={classNames(
          'container-slim',
          'relative',
          'z-10',
          withLogo ? 'pt-32' : 'pt-40 md:pt-64 xl:pt-[4vw] 2xl:pt-[7vw]'
        )}
      >
        {withLogo && (
          <Link to="/">
            <img src="/images/volcaneers-paima-logo.svg" alt="" className="mb-32 md:mb-80" />
          </Link>
        )}
        <h1 className="font-volcaneers text-48 md:text-60 2xl:text-96 xl:max-w-[760px] leading-[56px] md:leading-[64px] text-black">
          <Trans>Volcaneers: Experience NFTs 2.0 Today</Trans>
        </h1>
        <div className="md:max-w-[40vw] xl:max-w-[520px]">
          <h2 className="text-20 leading-[28px] 2xl:text-24 text-black mb-32">
            <Trans>
              Volcaneers are the first Stateful NFTs to ever be launched. This new class of NFTs opens up a new world
              where the value of your NFT will accrue based on the achievements, stats, and other in-game accolades that
              your NFT achieves. Volcaneers are the first stepping stone into the up-and-coming world of Stateful NFTs.
            </Trans>
          </h2>
          <div className="w-full sm:max-w-[330px]">
            {renderBuyButton(t('Get Your Volcaneer'), {
              eventName: AnalyticsEvents.BUY_NFT,
              location: AnalyticsEventLocations.VOLCANEERS_HEADER
            })}
          </div>
        </div>
      </div>
      <Picture src="/images/volcaneers-nft-background.webp" classes="absolute left-0 right-0 bottom-0" />
      <Picture src="/images/volcaneers-nft-character.webp" classes="absolute right-[17vw] bottom-0 max-w-[40vw]" />
    </div>
  );
};

export default Hero;
