import { Trans } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';

const featuresList: WhatAreTileProps[] = [
  {
    title: <Trans>Affordable (Starting At 10 mADA)</Trans>,
    imagePath: '/images/volcaneers-what-are-affordable.png'
  },
  {
    title: <Trans>NFT's Value Depends On YOU!</Trans>,
    imagePath: '/images/volcaneers-what-are-price.png'
  },
  {
    title: <Trans>Own Your Game Achievements</Trans>,
    imagePath: '/images/volcaneers-what-are-supply.png'
  },
  {
    title: <Trans>NFT And Gaming Ready</Trans>,
    imagePath: '/images/volcaneers-what-are-stateful.png'
  }
];

interface WhatAreTileProps {
  title: string | ReactElement;
  imagePath: string;
}

const WhatAreTile = ({ title, imagePath }: WhatAreTileProps) => {
  return (
    <li className="bg-white rounded-[1600px] volcaneers-box-shadow-32-40-6 flex items-center max-h-[120px] md:max-h-[140px] pr-24">
      <img src={imagePath} alt="" className="max-w-[100px] sm:max-w-[120px] md:max-w-[140px] mr-6 ml-24" />
      <h3 className="font-bold text-18 sm:text-20 md:text-24 text-black">{title}</h3>
    </li>
  );
};

const WhatAreVolcaneers = () => {
  return (
    <div className="relative z-20">
      <div className="bg-volcaneers-light relative">
        <div className="volcaneers-bg-gradient pb-[30vw] lg:pb-[13vw]">
          <div className="container-slim pt-48 md:pt-80">
            <h2 className="text-36 md:text-48 2xl:text-56 font-black text-black mb-48">
              <Trans>Volcaneers: A New Innovation in NFTs (Stateful NFTs)</Trans>
            </h2>
            <ul className="space-y-24 lg:grid lg:grid-cols-2 lg:space-y-0 lg:gap-32 relative z-20">
              {featuresList.map(({ title, imagePath }, index) => (
                <WhatAreTile title={title} imagePath={imagePath} key={index} />
              ))}
            </ul>
          </div>
        </div>
        <img src="/images/volcaneers-lava-2-illustration.svg" alt="" className="w-full absolute -bottom-[10vw] z-10" />
      </div>
    </div>
  );
};

export default WhatAreVolcaneers;
