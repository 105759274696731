import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { useWeb3Context } from '../../../hooks/useWeb3Context';
import { RenderBuyButtonInterface } from '../../../services/types';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';
import * as Analytics from '../../../services/analytics-utils';

import VolcaneersBuyModal from '../../Modal/VolcaneersBuyModal';
import NoWalletModal from '../NoWalletModal';
import NFTCarouselCardSingle, { NFTCarouselCardSingleProps } from './NFTCarouselCardSingle';

interface NFTCarouselCardCurrentProps extends NFTCarouselCardSingleProps {
  renderBuyButton?: RenderBuyButtonInterface;
  isComingSoon?: boolean;
}

const NFTCarouselCardCurrent: React.FC<NFTCarouselCardCurrentProps> = ({
  nftPrice,
  tokenId,
  imageModal,
  totalSupply,
  isComingSoon = false,
  renderBuyButton
}: NFTCarouselCardCurrentProps) => {
  const { connected, currentAccount } = useWeb3Context();
  const { t } = useTranslation();

  const buyNftNav = () => {
    if (!connected) {
      return (
        <NoWalletModal
          buttonProps={{
            disabled: isComingSoon
          }}
        />
      );
    }

    return (
      <VolcaneersBuyModal
        imageModal={imageModal}
        nftPrice={Number(nftPrice)}
        tokenId={tokenId.toString()}
        nftTotal={Number(totalSupply)}
        nftStatus={t('CURRENT')}
        buttonProps={{
          disabled: isComingSoon
        }}
        buttonOnClick={() => {
          Analytics.event(AnalyticsEvents.BUY_NFT, currentAccount, AnalyticsEventLocations.VOLCANEERS_CAROUSEL);
        }}
      />
    );
  };

  return (
    <NFTCarouselCardSingle
      nftPrice={nftPrice}
      tokenId={tokenId}
      imageModal={imageModal}
      totalSupply={totalSupply}
      buyComponent={isComingSoon && renderBuyButton ? renderBuyButton('Buy NFT') : buyNftNav()}
      cardWidth={328}
      barText={isComingSoon ? undefined : t('CURRENT')}
      isComingSoon={isComingSoon}
      showTotalSupply={!isComingSoon}
    />
  );
};

export default NFTCarouselCardCurrent;
