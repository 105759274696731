import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import { AnalyticsEventLocations, AnalyticsEvents } from '../../../analytic-events';
import { useWeb3Context } from '../../../hooks/useWeb3Context';
import * as Analytics from '../../../services/analytics-utils';

import DiscordIcon from '../../../../static/images/icon-discord-full.inline.svg';
import RedditIcon from '../../../../static/images/icon-reddit-full.inline.svg';
import TwitterIcon from '../../../../static/images/icon-twitter-full.inline.svg';
import YoutubeIcon from '../../../../static/images/icon-youtube-full.inline.svg';

import { socialsConfig } from '../../../config';

import Picture from './Picture';

interface SocialItemProps {
  icon: JSX.Element;
  title: string;
  linkText: string;
  linkUrl: string;
  onClick?: () => void;
}

const SocialItem = ({ icon, title, linkText, linkUrl, onClick }: SocialItemProps) => {
  return (
    <a
      href={linkUrl}
      title={linkText}
      onClick={() => onClick?.()}
      target="_blank"
      className="group hover:cursor-pointer bg-white py-25 px-40 md:py-24 md:px-48 rounded-[1600px] flex items-center justify-center md:justify-start space-x-16 md:space-x-40 md:flex-1 volcaneers-box-shadow-32-40-6"
    >
      <div className="fill-volcaneers-primary-default group-hover:fill-volcaneers-primary-dark w-[48px] h-[48px] md:w-[72px] md:h-[72px]">
        {icon}
      </div>
      <div>
        <h3 className="font-base font-bold text-24 2xl:text-32">{title}</h3>
        <span className="mt-4 block font-base text-volcaneers-primary-default font-bold group-hover:underline">
          {linkText}
        </span>
      </div>
    </a>
  );
};

const JoinUs = () => {
  const { currentAccount } = useWeb3Context();

  return (
    <section className="volcaneers-bg-gradient pt-[20vw] md:pt-160 lg:pt-[10vw] lg:pb-[24vw] 2xl:pt-[5vw] 2xl:pb-[24vw] pb-[44vw] md:pb-[34vw] -mt-[10vw] relative">
      <div className="container-slim relative z-10">
        <div className="max-w-[80vw] md:max-w-[60vw] lg:max-w-[530px] mx-auto">
          <h2 className="font-volcaneers text-48 md:text-64 2xl:text-96 text-black text-center">
            <Trans>Join the community</Trans>
          </h2>
        </div>
        <div className="max-w-[80vw] md:max-w-[60vw] lg:max-w-[800px] mx-auto">
          <p className="text-center text-20 md:text-24 leading-8">
            <Trans>
              Join the Paima community to get the latest news, updates, and announcements about the Volcaneers, upcoming
              compatible games, and more about Stateful NFTs.
            </Trans>
          </p>
        </div>
        <ul className="mt-48 grid grid-cols-1 gap-24 md:grid-cols-2 md:gap-32 md:flex-wrap">
          <SocialItem
            icon={<TwitterIcon />}
            title="Twitter"
            linkText="@PaimaStudios"
            linkUrl={socialsConfig.twitterUrl}
            onClick={() => {
              Analytics.event(
                AnalyticsEvents.TWITTER,
                currentAccount,
                AnalyticsEventLocations.VOLCANEERS_JOIN_COMMUNITY
              );
            }}
          />
          <SocialItem
            icon={<DiscordIcon />}
            title="Discord"
            linkText="@PaimaStudios"
            linkUrl={socialsConfig.discordUrl}
            onClick={() => {
              Analytics.event(
                AnalyticsEvents.DISCORD,
                currentAccount,
                AnalyticsEventLocations.VOLCANEERS_JOIN_COMMUNITY
              );
            }}
          />
          <SocialItem
            icon={<YoutubeIcon />}
            title="Youtube"
            linkText="@PaimaStudios"
            linkUrl={socialsConfig.youtubeUrl}
            onClick={() => {
              Analytics.event(
                AnalyticsEvents.YOUTUBE,
                currentAccount,
                AnalyticsEventLocations.VOLCANEERS_JOIN_COMMUNITY
              );
            }}
          />
          <SocialItem
            icon={<RedditIcon />}
            title="Reddit"
            linkText="r/paima"
            linkUrl={socialsConfig.redditUrl}
            onClick={() => {
              Analytics.event(
                AnalyticsEvents.REDDIT,
                currentAccount,
                AnalyticsEventLocations.VOLCANEERS_JOIN_COMMUNITY
              );
            }}
          />
        </ul>
      </div>
      <Picture
        src="/images/volcaneers-join-us-volcano-mobile.png"
        mdSrc="/images/volcaneers-join-us-volcano-tablet.png"
        lgSrc="/images/volcaneers-join-us-volcano.png"
        classes="absolute bottom-0"
      />
    </section>
  );
};

export default JoinUs;
