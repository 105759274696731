import React, { useEffect, useState } from 'react';
import { useLayoutContext } from '../../../hooks/useLayoutContext';

import { useWeb3Context } from '../../../hooks/useWeb3Context';
import * as Analytics from '../../../services/analytics-utils';
import { CarouselType, RenderBuyButtonInterface } from '../../../services/types';
import { getCauroselData } from '../../../services/utils';

import CookieConsentBanner from '../../CookieConsentBanner';
import VolcaneersBuyModal from '../../Modal/VolcaneersBuyModal';
import Footer from '../Footer';
import Header from '../header/Header';
import NoWalletModal from '../NoWalletModal';
import AffordableNFTs from './AffordableNFTs';
import BuyYourVolcaneer from './BuyYourVolcaneer';
import ConnectWallet from './ConnectWallet';
import FAQ from './FAQ';
import Hero from './Hero';
import JoinUs from './JoinUs';
import WhatAreVolcaneers from './WhatAreVolcaneers';

const VolcaneersLayout = () => {
  const { connected, currentAccount } = useWeb3Context();
  const { comingSoonMode } = useLayoutContext();

  const [nftData, setNftData] = useState<CarouselType>({} as CarouselType);

  useEffect(() => {
    (async () => {
      const data = await getCauroselData();
      setNftData(data);
    })();
  }, []);

  const renderBuyButton: RenderBuyButtonInterface = (buttonText: string, analyticsEvent): JSX.Element | null => {
    if (!connected) {
      return (
        <NoWalletModal
          currentAccount={currentAccount}
          buttonText={buttonText}
          buttonProps={{
            disabled: comingSoonMode
          }}
        />
      );
    }

    if (!nftData.current) {
      return null;
    }

    return (
      <VolcaneersBuyModal
        imageModal={nftData.current.data.image}
        nftPrice={Number(nftData.nftPrice)}
        tokenId={nftData.current.data.tokenId.toString()}
        nftTotal={Number(nftData.totalSupply)}
        nftStatus={'current'}
        buttonText={buttonText}
        buttonProps={{
          disabled: comingSoonMode
        }}
        buttonOnClick={() => {
          if (!analyticsEvent) return;
          Analytics.event(analyticsEvent.eventName, currentAccount, analyticsEvent.location, analyticsEvent.properties);
        }}
      />
    );
  };

  return (
    <div className="relative">
      <Header showSubHeader="volcaneers" connected={connected} currentAccount={currentAccount} />
      <CookieConsentBanner />
      <Hero renderBuyButton={renderBuyButton} />
      <WhatAreVolcaneers />
      <AffordableNFTs carouselData={nftData} comingSoonMode={comingSoonMode} renderBuyButton={renderBuyButton} />
      <ConnectWallet />
      <BuyYourVolcaneer renderBuyButton={renderBuyButton} />
      <FAQ />
      <JoinUs />
      <Footer withLavaIllustration />
    </div>
  );
};

export default VolcaneersLayout;
