import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import NFTImage from './modals/NFTImage';

type CardWidthType = 328 | 272 | 232 | 200 | 192;

export interface NFTCarouselCardSingleProps {
  nftPrice: number;
  tokenId: number;
  imageModal: string;
  totalSupply: number;
  buyComponent?: JSX.Element | null;
  cardWidth?: CardWidthType;
  isSold?: boolean;
  isComingSoon?: boolean;
  barText?: string;
  location?: 'prev' | 'next';
  showTotalSupply?: boolean;
}

// Tailwinds JIT needs to have strings readable, string literals would
// not work with JIT
const mapCardWidthToClassname: Record<CardWidthType, string> = {
  328: 'w-[328px]',
  272: 'w-[272px]',
  232: 'w-[232px]',
  200: 'w-[200px]',
  192: 'w-[192px]'
};

const NFTCarouselCardSingle = ({
  nftPrice,
  tokenId,
  imageModal,
  totalSupply,
  buyComponent,
  cardWidth = 328,
  isSold = false,
  isComingSoon = false,
  barText,
  location,
  showTotalSupply = true
}: NFTCarouselCardSingleProps) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'volcaneers-box-shadow-40-90',
        'rounded-3xl',
        'mx-auto',
        'my-0',
        'h-fit',
        'rounded-[32px]',
        'relative',
        'z-10',
        mapCardWidthToClassname[cardWidth],
        location === 'next' && 'next-nft',
        location === 'prev' && 'prev-nft'
      )}
    >
      <div className="bg-white z-20 rounded-[32px]">
        <div className="relative">
          <div className="relative">
            <NFTImage imageModal={imageModal} />
            {isComingSoon && (
              <div className="w-full h-full absolute top-0 rounded-tl-[24px] rounded-tr-[24px] flex justify-center items-center volcaneers-bg-sold-overlay backdrop-blur-[3px]">
                <h1 className="text-32 text-white text-center font-bold font-base rotate-[-15deg] uppercase">
                  {t('COMING SOON')}
                </h1>
              </div>
            )}
            {isSold && (
              <div className="w-full h-full absolute top-0 rounded-tl-[24px] rounded-tr-[24px] flex justify-center items-center volcaneers-bg-sold-overlay backdrop-blur-[3px]">
                <h1 className="text-32 text-white text-center font-bold font-base rotate-[-15deg]">{t('SOLD')}</h1>
              </div>
            )}
            {barText && (
              <p className="bg-white absolute left-0 right-0 mx-auto -bottom-[20px] text-14 leading-4 py-12 px-16 rounded-[24px] max-w-[120px] text-center border border-volcaneers-border-grey font-bold">
                {barText}
              </p>
            )}
          </div>
          <div className="px-16 pb-24 pt-32">
            <h3 className="text-center text-18 leading-6 font-bold">{`${nftPrice} mADA`}</h3>
            {showTotalSupply && (
              <p className="text-center text-14 leading-4 mt-4 text-volcaneers-grey">{`#${tokenId}/${totalSupply}`}</p>
            )}
            <div className="mt-24">{buyComponent}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NFTCarouselCardSingle;
