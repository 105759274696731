import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

const NFTCarouselCardLoader = (props: IContentLoaderProps) => (
  <div className="w-[48%] md:w-[197px] lg:w-[216px] nft-current mb-120 mt-60 lg:mb-[10vw]">
    <ContentLoader
      speed={2}
      width={190}
      height={250}
      viewBox="0 0 190 250"
      backgroundColor="#EDF2F0"
      foregroundColor="#DEE3E1"
      style={{ width: '100%' }}
      {...props}
    >
      <rect x="7" y="230" rx="2" ry="2" width="178" height="15" />
      <rect x="7" y="210" rx="2" ry="2" width="178" height="15" />
      <rect x="7" y="3" rx="8" ry="8" width="177" height="195" />
    </ContentLoader>
  </div>
);

export default NFTCarouselCardLoader;
